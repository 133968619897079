import React, { Component, useMemo } from "react";
import { Col, Row, Container, Button } from "react-bootstrap";

function CloudinaryUploadWidget() {
  const cloudName = "hzxyensd5"; // replace with your own cloud name
  const uploadPreset = "aoh4fpwm"; // replace with your own upload preset
  // let element = document.getElementById("cloudinary-media-widget");
  /**
   * see documentation
   * https://cloudinary.com/documentation/media_editor
   */
  // let mediaWidget = useMemo(() => {
  //   // if (element) {
  //   // @ts-ignore
  //   return window.cloudinary
  //     .mediaEditor
  //     //   {
  //     //   appendTo: "#cloudinary-media-widget",
  //     // }
  //     ();
  //   // }
  // }, [cloudName]);

  // Remove the comments from the code below to add
  // additional functionality.
  // Note that these are only a few examples, to see
  // the full list of possible parameters that you
  // can add see:
  //   https://cloudinary.com/documentation/upload_widget_reference

  let widget = useMemo(() => {
    // @ts-ignore
    let localWidget = window.cloudinary.createUploadWidget(
      {
        cloudName: cloudName,
        uploadPreset: uploadPreset,
        // cropping: true, //add a cropping step
        // showAdvancedOptions: true,  //add advanced options (public_id and tag)
        sources: [
          "local",
          "url",
          "image_search",
          "dropbox",
          "shutterstock",
          "gettyimages",
          "google_drive",
        ], // restrict the upload sources to URL and local files
        multiple: false, //restrict upload to a single file
        folder: "user_images", //upload files to the specified folder
        // tags: ["users", "profile"], //add the given tags to the uploaded files
        // context: {alt: "user_uploaded"}, //add the given context data to the uploaded files
        // clientAllowedFormats: ["images"], //restrict uploading to image files only
        // maxImageFileSize: 2000000,  //restrict file size to less than 2MB
        // maxImageWidth: 2000, //Scales the image down to a width of 2000 pixels before uploading
        // theme: "purple", //change to a purple theme
      },
      //   @ts-ignore
      (error, result) => {
        if (!error && result && result.event === "success") {
          console.log("Done! Here is the image info: ", result.info);
          let publicId = result.info.public_id;
          // @ts-ignore
          //@ts-ignore
          let widget = window.cloudinary.mediaEditor();
          widget.update({
            publicIds: [result.info.public_id],
            // publicIds: [],
            cloudName: cloudName,
            image: {
              steps: [
                "resizeAndCrop",
                // "imageOverlay",
                // "textOverlays",
                "export",
              ],
              resizeAndCrop: {
                toggleAspectRatio: true,
                aspectRatioLock: true,
                flip: true,
                rotate: true,
                presets: [
                  "original",
                  "square",
                  "landscape-16:9",
                  "landscape-4:3",
                  "portrait-3:4",
                  "portrait-9:16",
                ],
              },
              export: {
                quality: ["auto", "best", "good", "eco", "low"],
                formats: ["jpg", "png", "webp"],
                download: true,
                share: false,
              },
            },
          });
          widget.show();
          widget.on(
            "export",
            // @ts-ignore
            function (data) {
              let downloadUrl = data.assets[0].downloadUrl;
              window.location = downloadUrl;
              widget.destroy();
            }
          );
        }
      }
    );
    return localWidget;
  }, [cloudName]);

  let button = (
    <Button
      onClick={(event) => {
        widget.open();
      }}
      className="w-100"
      id="upload_widget"
      variant="outline-light"
      // className="cloudinary-button"
    >
      Get Started
    </Button>
  );

  return (
    <Row className="justify-content-center">
      <Col sm={2}>{button}</Col>
    </Row>
  );
}

export default CloudinaryUploadWidget;

// access_mode: "public"
// ​
// asset_id: "f4601038f2fed8742d41941fcf5a1fa1"
// ​
// batchId: "uw-batch5"
// ​
// bytes: 2233680
// ​
// created_at: "2023-07-16T20:33:40Z"
// ​
// delete_token: "aef72730008c18bf72c906e1065fdf8852fae356b696eca84570f460038ec89ae68e72888014800e22e2ba9e65e38ddefe3386b16aad62e418544a119460657d2eed3f19ef2d1100aa2aba16b81d6c79c437a06d3b15121c8fccd02c73d9fee850c03a0b681c72b9ebe31600bca3a197b0bc18cccfb72f19743aa3fda63c12c5ecfa1e1ae54faa045c590d337118a59b"
// ​
// etag: "65b2b7845489c380a9ad64c08b5752a9"
// ​
// folder: "user_images"
// ​
// format: "jpg"
// ​
// height: 4032
// ​
// id: "uw-file6"
// ​
// original_filename: "original150X100"
// ​
// path: "v1689539620/user_images/vqs1gictikq6xzfdlkdi.jpg"
// ​
// placeholder: false
// ​
// public_id: "user_images/vqs1gictikq6xzfdlkdi"
// ​
// resource_type: "image"
// ​
// secure_url: "https://res.cloudinary.com/hzxyensd5/image/upload/v1689539620/user_images/vqs1gictikq6xzfdlkdi.jpg"
// ​
// signature: "2b79de69a7ab678c44c9504b2ec673f7f0040dc9"
// ​
// tags: Array [ "widget" ]
// ​​
// 0: "widget"
// ​​
// length: 1
// ​​
// <prototype>: Array []
// ​
// thumbnail_url: "https://res.cloudinary.com/hzxyensd5/image/upload/c_limit,h_60,w_90/v1689539620/user_images/vqs1gictikq6xzfdlkdi.jpg"
// ​
// type: "upload"
// ​
// url: "http://res.cloudinary.com/hzxyensd5/image/upload/v1689539620/user_images/vqs1gictikq6xzfdlkdi.jpg"
// ​
// version: 1689539620
// ​
// version_id: "76155f00c432ba75e0b82fc5d87a752a"
// ​
// width: 3024
