import React, { Component, useMemo } from "react";
// or less ideally
import { Button } from "react-bootstrap";

function EbayBanner() {
  // each of these config IDs represents an id of an ebay smart placement campaign
  let smartPlacementIds: Array<string> = [
    "ffe078a0af678100c57992c7", //tech ad unit
    "ffe09d4e0df7f952f96ec2b2", //Clothing -> shoes
    "ffe0aafb9b97903736d66e76", // The 'Auto' campaign
  ];
  //   returns an integer between min (included) and max (included):
  let randomInteger = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  //randomly choose a placement banner to show to viewers
  let placementIndex: number = randomInteger(0, smartPlacementIds.length - 1);
  let smartPlacement: string = smartPlacementIds[placementIndex];
  return (
    <div className="ebay-banner mt-5">
      <ins className="epn-placement" data-config-id={smartPlacement}></ins>
    </div>
    // <button
    //   onClick={(event) => {
    //     widget.open();
    //   }}
    //   id="upload_widget"
    //   className="cloudinary-button"
    // >
    //   Upload
    // </button>
  );
}

export default EbayBanner;
