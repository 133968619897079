import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Cloudinary } from "@cloudinary/url-gen";
import CloudinaryUploadWidget from "./CloudinaryUploadWidget";
// Example
import { CloudinaryImage } from "@cloudinary/url-gen/assets/CloudinaryImage";
import {
  AdvancedImage,
  accessibility,
  responsive,
  lazyload,
  placeholder,
} from "@cloudinary/react";

import { Col, Row, Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import CloudinaryMediaEditor from "./CloudinaryMediaEditor";
import EbayBanner from "./ad_banners/EbayBanner";

function App() {
  // Create a Cloudinary instance and set your cloud name.
  const cld = new Cloudinary({
    cloud: {
      cloudName: "hzxyensd5",
    },
  });

  let img = cld.image("user_images/vqs1gictikq6xzfdlkdi"); // needs to be the publicID of the image.

  // user_images/vqs1gictikq6xzfdlkdi
  return (
    <>
      <Navbar
        expand="lg"
        className="bg-body-tertiary"
        bg="dark"
        data-bs-theme="dark"
      >
        <Container fluid>
          <Navbar.Brand href="#home">Picture.Photo</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#home">Home</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid className="outer-container">
        <Row>
          <Col sm={12}>
            <Row>
              <div className="content-header mt-2">
                <h1>Welcome to Picture.Photo</h1>
                <h2>
                  A helpful web tool for resizing, cropping, and reformatting
                  images.
                </h2>
              </div>
            </Row>
            <Row className="app-content mt-5">
              <Col sm={12} className="d-flex justify-content-center">
                <p className="mb-5">
                  This web tool allows you to resize, crop, and adjust the
                  quality of an image as well as convert between webp, jpeg, and
                  png formats easily.
                </p>
              </Col>
              <CloudinaryUploadWidget />
            </Row>
          </Col>
        </Row>
      </Container>
      <EbayBanner />
    </>
  );
}

export default App;

//            {/* TODO change this logo  */}
{
  /* <img src={logo} className="App-logo" alt="logo" /> */
}
